import { useMemo } from 'react';

import { createActionsHook, createStateHook, createStore } from 'react-sweet-state';

type Store = {
	isRovoEnabled: boolean;
	reason?: string;
};

const rovoEntitlementStore = createStore({
	initialState: {
		isRovoEnabled: false,
	} as Store,
	name: 'RovoEntitlementStore',
	actions: {
		setIsRovoEnabled:
			(isRovoEnabled: boolean, reason?: string) =>
			({ setState }) => {
				setState({ isRovoEnabled, reason });
			},
	},
});

const useStateHook = createStateHook(rovoEntitlementStore);
export const useRovoEntitlement = () => {
	const result = useStateHook();

	return {
		...result,
		isRovoEnabled: result.isRovoEnabled,
	};
};
export const useRovoEntitlementActions = createActionsHook(rovoEntitlementStore);

/**
 * This component is used to store the isRovoEnabled flag
 * the `isRovoEnabled` flag will be used in few places in the app to determine whether to return noop behaviour
 */
export const RovoEntitlementSetter = ({ isRovoEnabled }: { isRovoEnabled: boolean }) => {
	const { setIsRovoEnabled } = useRovoEntitlementActions();
	useMemo(() => {
		setIsRovoEnabled(isRovoEnabled);
	}, [isRovoEnabled, setIsRovoEnabled]);

	return null;
};
