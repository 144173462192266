import gql from 'graphql-tag';

import { UserPrivilegeFragment } from './UserPrivilege.fragment';

export const UseIsProductAdminQuery = gql`
	query UseIsProductAdminQuery {
		...UserPrivilegeFragment
	}

	${UserPrivilegeFragment}
`;
