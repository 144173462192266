import type { FC } from 'react';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl-next';

import type { SearchCSS } from '@atlaskit/atlassian-navigation';

import { ProductSearchInputSkeletonInteractive } from '@atlassian/product-search-dialog/skeleton';

import { CONTEXT_PATH } from '@confluence/named-routes/src';
import type { RoutesContextType } from '@confluence/route-manager';
import { RoutesContext } from '@confluence/route-manager';

import { i18n } from './i18n';

interface InteractiveSearchInputSkeletonProps {
	theme?: SearchCSS;
	placeholder?: string;
	shouldFillContainer?: boolean;
}

export const generateInteractiveSkeletonOnEnter = (push: RoutesContextType['push']) => {
	return (e: React.KeyboardEvent) => {
		const input = e.target as HTMLInputElement;
		input.blur();
		push(`${CONTEXT_PATH}/search/?text=${input.value}`);
	};
};

export const InteractiveSearchInputSkeleton: FC<InteractiveSearchInputSkeletonProps> = ({
	theme,
	shouldFillContainer,
}) => {
	const intl = useIntl();
	const { push } = useContext(RoutesContext);

	return (
		<ProductSearchInputSkeletonInteractive
			theme={theme}
			placeholder={intl.formatMessage(i18n.placeholder)}
			onEnter={generateInteractiveSkeletonOnEnter(push)}
			shouldFillContainer={shouldFillContainer}
			stickySearchEnabled
		/>
	);
};
