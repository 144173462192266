// web items
export const TOOLS_MENU_CONTAINER = 'system.content.action';
export const TOOLS_MENU_MARKER = 'system.content.action/marker';
export const TOOLS_MENU_LEADING = 'system.content.action/leading';
export const TOOLS_MENU_PRIMARY = 'system.content.action/primary';
export const TOOLS_MENU_SECONDARY = 'system.content.action/secondary';
export const TOOLS_MENU_MODIFY = 'system.content.action/modify';
export const TOOLS_MENU_FEEDBACK = 'system.content.action/feedback';
export const PAGE_BUTTONS = 'system.content.button';
export const SYSTEM_DASHBOARD = 'system.dashboard';
export const SYSTEM_DASHBOARD_BUTTON = 'system.dashboard.button';
export const SYSTEM_CONTENT_BYLINE = 'system.content.byline';
export const SYSTEM_CONTENT_METADATA = 'system.content.metadata';
export const PAGE_METADATA_BANNER = 'page.metadata.banner';
export const SYSTEM_HEADER_LEFT = 'system.header/left';
export const SYSTEM_HEADER_LEFT_BUTTON_GROUP = 'system.header/left.button.group';
export const SYSTEM_HELP = 'system.help/pages';
export const SYSTEM_USER_ANONYMOUS = 'system.user/anonymous';
export const SYSTEM_USER = 'system.user';
export const SYSTEM_ADMIN_SITE_SECTION = 'system.admin.site/site_admin_section';
export const SPACE_SIDEBAR = 'system.space.sidebar/main-links';
export const SYSTEM_ADMIN = 'system.admin';
export const SYSTEM_EDITOR_LINK_BROWSER_TABS = 'system.editor.link.browser.tabs';
export const SYSTEM_EDITOR_PRECURSOR_BUTTONS = 'system.editor.precursor.buttons';
export const CREATE_DIALOG = 'system.create.dialog/content';
export const CREATE_SPACE_DIALOG = 'system.create.space.dialog/content';
export const ACTION_PANEL = 'page.view.selection/action-panel';

// web panels
export const DASHBOARD_LEFT = 'atl.dashboard.left';
export const DASHBOARD_SECONDARY = 'atl.dashboard.secondary';
export const ATL_GENERAL = 'atl.general';
export const ATL_FOOTER = 'atl.footer';
export const ATL_PAGE_METADATA = 'atl.page.metadata.banner';
export const ATL_PAGE_CONTENT_FOOTER_ACTIONS = 'atl.page.content.footer.actions';
export const ATL_EDITOR_SAVEBAR = 'atl.editor.savebar';

// web sections
export const SYSTEM_ADMIN_SITE_ADMINISTRATION = 'system.admin/siteadministration';
export const SYSTEM_ADMIN_SITE_ADMINISTRATION_SECURITY = 'system.admin/security';
