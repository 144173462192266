import React, { useContext, createContext } from 'react';
import merge from 'lodash/merge';
import { B100, N0, N100, N200, N600 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { type CSSObject } from '@emotion/react';

// WARNING: The following types are duplicated from '@atlaskit/atlassian-navigation' so that we can avoid having to include atlassian-navigation as a dependency just for these types
// The more correct solution would be to extract these types and have them in a separate package that we both import from, but this will do for now
type CSSProperties = CSSObject & {
	backgroundColor: string;
	color: string;
};

export type SearchCSS = {
	default: CSSProperties;
	focus: CSSObject;
	hover: CSSObject;
};
export type SearchTheme = SearchCSS;

/**
 * Pls see the function generateTheme inside - atlassian-frontend/packages/navigation/atlassian-navigation/src/theme/themeGenerator.ts
 * This default generator should overwrite the exact same properties which are being produced by the theme generator.
 */
const themes: Record<'default', SearchCSS> = {
	default: {
		default: {
			backgroundColor: token('color.background.input', N0),
			color: token('color.text.subtlest', N200),
			borderColor: token('color.border.input', N100),
			borderWidth: token('border.width', '1px'),
			iconColor: token('color.icon.subtle', N600),
		},
		focus: {
			borderColor: token('color.border.focused', B100),
			backgroundColor: token('color.background.input.pressed', N0),
			boxShadow: `inset 0 0 0 ${token('border.width', '1px')} ${token('color.border.input', N100)}`,
		},
		hover: {
			backgroundColor: token('color.background.input.hovered', 'rgba(222, 235, 255, 0.9)'),
		},
	},
};

type PartialSearchThemeDepthOf1 = {
	[K in keyof SearchCSS]?: Partial<SearchCSS[K]>;
};

const getTheme = (searchCSS?: PartialSearchThemeDepthOf1): SearchCSS => {
	if (!searchCSS) {
		return themes.default;
	}
	return merge({}, themes.default, searchCSS);
};

const ctx = createContext<SearchCSS>(themes.default);

export type ThemeProviderProps = {
	// todo - deprecate "value" (requires product update)
	value?: SearchCSS | SearchTheme;
	partialSearchCSS?: PartialSearchThemeDepthOf1;
};

const ThemeProvider = ({
	value,
	partialSearchCSS,
	children,
}: React.PropsWithChildren<ThemeProviderProps>) => {
	const theme = getTheme(partialSearchCSS || value);
	return <ctx.Provider value={theme}>{children}</ctx.Provider>;
};

const useTheme = () => {
	return useContext(ctx);
};

// todo - deprecate (requires product update)
// eslint-disable-next-line
const _ThemeContext = ctx;

// todo - deprecate (requires product update)
// eslint-disable-next-line
const _createSearchTheme = (suppliedTheme: SearchCSS): SearchCSS => {
	return suppliedTheme;
};

export {
	// private, used only by tests
	themes as _themes,
	ThemeProvider,
	useTheme,
	// deprecated
	_ThemeContext,
	// deprecated
	_createSearchTheme,
};
