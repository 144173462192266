// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import {
	dialogWidth,
	zIndex,
	dialogPageCentreWidth,
	dialogPageTakeoverWidth,
	enlargedInputFieldHeight,
	inputFieldHeight,
} from '../style-utils';

interface IsExpandedProp {
	isExpanded: boolean;
	shouldFillContainer?: boolean;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const AbsoluteContainer = styled('div')<{
	isExpanded: boolean;
	shouldFillContainer?: boolean;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
}>({}, (props) => {
	if (props.isExpanded) {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		return css({
			position: 'absolute',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
			...(props.shouldFillContainer && { left: 0 }),
			right: 0,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			zIndex: zIndex.searchDialog,
		});
	}
	return {};
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchDialogAnchor = styled('div')(
	{
		position: 'relative',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props: IsExpandedProp) => {
		if (props.isExpanded) {
			// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			return css`
				${
					/* eslint-disable @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''
				}

				@media screen and (max-width: ${dialogPageCentreWidth}) {
					${/* eslint-enable */ ''}
					position: ${props.shouldFillContainer ? 'static' : 'fixed'};
					${
						/* eslint-disable @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''
					}
					z-index: ${zIndex.searchDialog};
					left: calc((100% - ${dialogWidth}) / 2);
					${/* eslint-enable */ ''}
				}

				${
					/* eslint-disable @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''
				}
				@media screen and (max-width: ${dialogPageTakeoverWidth}px) {
					${/* eslint-enable */ ''}

					${props.shouldFillContainer && 'position: fixed;'};
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					display: flex;
					flex-direction: column;
					overflow: auto;
				}
			`;
		}
		return {};
	},
);

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchAnchorWrapper = styled.div<{ shouldFillContainer?: boolean }>`
	position: relative;
	/* Prevent the input from being 200px when the icon is at the maximum icon width */
	@media screen and (min-width: ${dialogPageTakeoverWidth + 1}px) {
		${(props) => (props.shouldFillContainer ? 'width: 100%;' : 'min-width: 200px;')};
	}
	${(props) => (props.shouldFillContainer ? 'max-width: 780px;' : '')}
	width: ${inputFieldHeight.px};
	height: ${inputFieldHeight.px};
`;

// FIXME: convert-props-syntax rule doesn't catch this
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EnlargedSearchAnchorWrapper = styled(SearchAnchorWrapper)<IsExpandedProp>`
	height: ${(props) => (props.isExpanded ? enlargedInputFieldHeight.px : inputFieldHeight.px)};
`;
