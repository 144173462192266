import { LoadableAfterPaint, LoadableLazy } from '@confluence/loadable';

export {
	CONVERSATION_ASSISTANT_CONTAINER_ID,
	CONVERSATION_ASSISTANT_CONTAINER_WIDTH,
} from '@atlassian/conversation-assistant/utils';

export { RovoEntitlementSetter } from '@atlassian/conversation-assistant-entitlement';

export const ConfluenceConversationAssistant = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ConversationAssistant" */ './main'))
			.ConfluenceConversationAssistant,
});

export const ConversationAssistantNavBarButton = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ConversationAssistantNavBarButton" */ './navBarButton'
			)
		).ConversationAssistantNavBarButton,
	_noPreloadWhenRenderingSPA: true,
});
