// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';
import { gridSize, layers } from '@atlaskit/theme/constants';
import { N900, N200, N20, P300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

const GRID_UNIT = gridSize();

const AkGrid = (num: number = 1) => ({
	px: `${num * GRID_UNIT}px`,
	unitless: num * GRID_UNIT,
	multiple: (multiplier: number) => AkGrid(multiplier * num),
	half: () => AkGrid(0.5 * num),
	twice: () => AkGrid(2 * num),
});

export const zIndex = {
	searchDialog: layers.dialog(),
};

export const grid = AkGrid(1);

export const atlassianNavigationHeight = '56px';

export const collapsedSearchInputWidth = '200px';
export const filterLabelWidth = '150px';

export const dialogWidth = '780px';
export const dialogPageTakeoverWidth: number = 780;
export const dialogPageCentreWidth = '1130px';

export const inputFieldHeight = grid.multiple(4);
export const enlargedInputFieldHeight = grid.multiple(6);

export const primaryTextColour = token('color.text', N900);
export const itemLinkSecondaryColor = token('color.text.subtlest', N200);
export const itemHighlightColor = token('color.background.neutral.subtle.hovered', N20);
export const visitedLinkColor = token('color.link.visited', P300);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const nowrapEllipsis = css({
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
});
