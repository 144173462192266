import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import ArrowLeftIcon from '@atlaskit/icon/core/migration/arrow-left';
import { IconButton } from '@atlaskit/button/new';

import { useTheme } from '../theme';
import { SearchIcon } from '../icons';
import {
	MobileSearchIconWrapper,
	DesktopSearchIconWrapper,
	SearchInputContainer,
	SearchInputField,
	ArrowIconWrapper,
	HiddenTooltip,
} from './search-input.styled';
import { SearchInputOverlay } from './search-input-overlay';
import type { SearchCSS } from '../theme';

export interface Props {
	/**
	 * Whether the search input is currently in the expanded state or not.
	 */
	isExpanded: boolean;
	/**
	 * Callback that is called when the value in the search input changes
	 */
	onInput?: (value: string) => void;
	/**
	 * Callback that is called when the input is clicked
	 */
	onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
	/**
	 * Callback that is called when the user pressed enter while focused on this input
	 */
	onEnter?: (e: React.KeyboardEvent) => void;
	/**
	 * Callback that is called when the user clicks the \`<-\` (back button). This button is only available when the screen is below a certain width (mobile mode).
	 */
	onBack?: (e: React.MouseEvent<HTMLElement>) => void;
	/**
	 * Callback that is called when selection on the input changes
	 */
	onSelect?: (e: React.SyntheticEvent) => void;
	/**
	 * Placeholder text for the input
	 */
	placeholder?: string;
	/**
	 * Tooltip text for the input. The tooltip will appear when the user hovers over the input.
	 */
	tooltipContent?: JSX.Element;
	/**
	 * Value of the search input, if this provided then the component is considered controlled.
	 */
	value?: string | number | string[];
	/**
	 * Whether or not to show the x button that clears the input
	 */
	showClearButton?: boolean;
	/**
	 * Callback that is called to clear the search input.
	 */
	onClear?: () => void;
	/**
	 * Callback to set whether or not to allow the dialog to change expansion states
	 */
	allowChangeExpand?: (expand: boolean) => void;
	/**
	 * Label text for the Exit Search button
	 */
	exitSearchLabel?: string;
	/**
	 * Label text for the Clear Search button
	 */
	clearSearchLabel?: string;
	/**
	 * Button for toggling AI dialog
	 */
	toggleAISearchButton?: JSX.Element;
	/**
	 * The search input will fill all available width.
	 * Used in conjunction with the new navigation system.
	 */
	shouldFillContainer?: boolean;
	/**
	 * Aria label for the input (used in EnlargedSearchInput)
	 */
	ariaLabelContent?: string;
}

interface InternalProps {
	forwardRef?: React.Ref<HTMLInputElement>;
	theme: SearchCSS;
}

const MAX_INPUT_LENGTH = 500;

export const SearchInput: React.FunctionComponent<Props & InternalProps> = ({
	onInput,
	onClick,
	onEnter,
	isExpanded,
	placeholder,
	forwardRef,
	value,
	theme,
	tooltipContent,
	onBack,
	showClearButton,
	onClear,
	allowChangeExpand,
	exitSearchLabel,
	clearSearchLabel,
	onSelect,
	toggleAISearchButton,
	shouldFillContainer,
}) => {
	const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
		if (onInput) {
			onInput(e.currentTarget.value);
		}
	};

	const handleClick = (e: React.MouseEvent<HTMLElement> | undefined) => {
		if (onClick) {
			onClick(e);
		}
	};

	const handleTabEnter = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' && onClick) {
			onClick();
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.nativeEvent?.isComposing || e.keyCode === 229) {
			return;
		}
		if (!e.defaultPrevented && e.key === 'Enter') {
			e.preventDefault();
			onEnter && onEnter(e);
		}
	};

	const handleSelect = (e: React.SyntheticEvent) => {
		if (onSelect) {
			onSelect(e);
		}
	};

	const TooltipComponent = tooltipContent && !isExpanded ? undefined : HiddenTooltip;

	return (
		<Tooltip content={tooltipContent} component={TooltipComponent} position="bottom">
			<SearchInputContainer
				{...theme}
				shouldFillContainer={shouldFillContainer}
				isExpanded={isExpanded}
				role="search"
			>
				<MobileSearchIconWrapper isExpanded={isExpanded}>
					<IconButton
						label="Search"
						onClick={handleClick}
						icon={() => <SearchIcon theme={theme} isExpanded={isExpanded} size="medium" />}
					/>
				</MobileSearchIconWrapper>
				<DesktopSearchIconWrapper {...theme} isExpanded={isExpanded} onKeyPress={handleTabEnter}>
					<SearchIcon theme={theme} isExpanded={isExpanded} />
				</DesktopSearchIconWrapper>
				{isExpanded && (
					<ArrowIconWrapper {...theme} onClick={onBack}>
						<ArrowLeftIcon
							label={exitSearchLabel || 'Exit Search'}
							LEGACY_size="large"
							color="currentColor"
						/>
					</ArrowIconWrapper>
				)}
				<SearchInputField
					data-test-id="search-dialog-input"
					onInput={handleInput}
					onChange={handleInput}
					onKeyDown={handleKeyDown}
					onClick={handleClick}
					onSelect={handleSelect}
					isExpanded={isExpanded}
					placeholder={placeholder}
					ref={forwardRef || undefined}
					value={value}
					maxLength={MAX_INPUT_LENGTH}
					aria-label={placeholder}
					{...theme}
				/>
				{(showClearButton || !!toggleAISearchButton) && (
					<SearchInputOverlay
						theme={theme}
						showClearButton={showClearButton}
						onClear={onClear}
						allowChangeExpand={allowChangeExpand}
						clearSearchLabel={clearSearchLabel}
						toggleAISearchButton={toggleAISearchButton}
					/>
				)}
			</SearchInputContainer>
		</Tooltip>
	);
};

export default React.forwardRef<HTMLInputElement, Props>((props, ref) => {
	const theme = useTheme();
	return <SearchInput {...props} theme={theme} forwardRef={ref} />;
});
