import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	placeholder: {
		id: 'navigation-search-dialog.placeholder',
		defaultMessage: 'Search',
		description: 'Placeholder text for the search input when it is empty',
	},
	lastUpdatedDate: {
		id: 'navigation-search-dialog.last-updated-date',
		defaultMessage: 'Updated {datetime}',
		description: "Describes when the item was last updated, e.g. 'Updated 23 Jan'",
	},
});
