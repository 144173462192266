/*
 * An interactive version of the search input skeleton which allows users to
 * type into the skeleton's input.  The state of the skeleton input is saved in
 * the browser's storage (specifically within window.inputSkeletonState).
 * On the first render of this component, this stored state is used as initial
 * an initial state. This behaviour allows one instance of this component
 * to be replaced by another and appear to seamlessly act as a single component.
 *
 * This component is intended to be used for client side rendering only.
 */

import React, { type FC } from 'react';
import type { SearchCSS } from '@atlaskit/atlassian-navigation';
import {
	SearchInputSkeletonInteractive,
	type SearchInputSkeletonInteractiveProps,
	ThemeProvider,
} from '@atlassian/search-dialog/skeleton';

type ProductSearchInputSkeletonInteractiveProps = SearchInputSkeletonInteractiveProps & {
	theme?: SearchCSS;
};

export const ProductSearchInputSkeletonInteractive: FC<
	ProductSearchInputSkeletonInteractiveProps
> = (props) => {
	return (
		<ThemeProvider partialSearchCSS={props.theme}>
			<SearchInputSkeletonInteractive {...props} />
		</ThemeProvider>
	);
};
