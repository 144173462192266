export interface InputSkeletonState {
	query: string;
	placeholder: string;
	isFocused: boolean;
	selectionStart: number;
	selectionEnd: number;
	selectionDirection: 'forward' | 'backward' | 'none';
	listeners: SSREventListener[];
}

export type SSREventListener<
	TNode = Node,
	TEventName = EventName,
	TEventListener = EventListener,
> = [TNode, TEventName, TEventListener];

export type EventName = keyof HTMLElementEventMap;

export type EventListener = <T extends EventName>(
	this: HTMLElement,
	ev: HTMLElementEventMap[T],
) => any;
declare global {
	interface Window {
		inputSkeletonState?: InputSkeletonState;
	}
}

interface skeletonSelection {
	selectionStart: number;
	selectionEnd: number;
	selectionDirection: 'forward' | 'backward' | 'none';
}

const getInputSkeletonQuery = (): string => {
	return window?.inputSkeletonState?.query || '';
};

const setInputSkeletonQuery = (newQuery: string): void => {
	if (window?.inputSkeletonState) {
		window.inputSkeletonState.query = newQuery;
	}
};

const getInputSkeletonFocus = (): boolean => {
	return window?.inputSkeletonState?.isFocused || false;
};

const setInputSkeletonIsFocus = (newIsFocused: boolean): void => {
	if (window?.inputSkeletonState) {
		window.inputSkeletonState.isFocused = newIsFocused;
	}
};

const getInputSkeletonSelection = (): skeletonSelection => {
	const selectionStart = window?.inputSkeletonState?.selectionStart || 0;
	const selectionEnd = window?.inputSkeletonState?.selectionEnd || 0;
	const selectionDirection = window?.inputSkeletonState?.selectionDirection || 'none';

	return { selectionStart, selectionEnd, selectionDirection };
};

const setInputSkeletonSelection = (
	newSelectionStart: number,
	newSelectionEnd: number,
	newSelectionDirection: 'forward' | 'backward' | 'none',
): void => {
	if (window?.inputSkeletonState) {
		window.inputSkeletonState.selectionStart = newSelectionStart;
		window.inputSkeletonState.selectionEnd = newSelectionEnd;
		window.inputSkeletonState.selectionDirection = newSelectionDirection;
	}
};

const resetInputSkeletonState = () => {
	window.inputSkeletonState = {
		query: '',
		placeholder: '',
		isFocused: false,
		selectionStart: 0,
		selectionEnd: 0,
		selectionDirection: 'none',
		listeners: [],
	};
};

const removeInputSkeletonEventListeners = () => {
	if (window.inputSkeletonState) {
		window.inputSkeletonState.listeners.forEach((ssrEventListener) => {
			const [target, event, handler] = ssrEventListener;
			target.removeEventListener(event, handler);
		});
		window.inputSkeletonState.listeners = [];
	}
};

const removeInputSkeletonState = () => {
	if (window['inputSkeletonState']) {
		removeInputSkeletonEventListeners();
		delete window['inputSkeletonState'];
	}
};

export {
	getInputSkeletonQuery,
	setInputSkeletonQuery,
	getInputSkeletonFocus,
	setInputSkeletonIsFocus,
	getInputSkeletonSelection,
	setInputSkeletonSelection,
	resetInputSkeletonState,
	removeInputSkeletonEventListeners,
	removeInputSkeletonState,
};
