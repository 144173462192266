import { useEffect, useContext } from 'react';

import type { ExperienceAttributes } from '../ExperienceEvent';

import { ExperienceTrackerContext } from './ExperienceTrackerContext';

export type ExperienceSuccessProps = {
	name: string;
	attributes?: ExperienceAttributes;
};

export function ExperienceSuccess({ name, attributes }: ExperienceSuccessProps) {
	const experienceTracker = useContext(ExperienceTrackerContext);
	useEffect(
		() => experienceTracker.succeed({ name, attributes }),
		[name, attributes, experienceTracker],
	);

	return null;
}
