import React from 'react';
import AKSearchIcon from '@atlaskit/icon/core/migration/search';
import type { Size } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';
import { N800 } from '@atlaskit/theme/colors';

import type { SearchCSS } from '../theme';

interface Props {
	isExpanded: boolean;
	theme: SearchCSS;
	/**
	 * @default 'small'
	 */
	size?: Size;
	primaryColor?: string;
	label?: string;
}

export const SearchIcon = ({
	isExpanded,
	theme,
	size = 'small',
	primaryColor = token('color.text', N800),
	label = '',
}: Props) => (
	<AKSearchIcon
		label={label}
		LEGACY_primaryColor={isExpanded ? primaryColor : theme.default.color}
		LEGACY_size={size}
		// NOTE: color do not accept string type but here primaryColor is string, so need to use any
		color={isExpanded ? (primaryColor as any) : theme.default.color}
	/>
);
