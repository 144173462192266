import React, { type FC } from 'react';
import { ClearIconWrapper, InputBoxOverlayRight } from './search-input.styled';
import SelectClearIcon from '@atlaskit/icon/core/migration/cross-circle--select-clear';

import type { SearchCSS } from '../theme';

type SearchInputOverlayProps = {
	allowChangeExpand?: (expand: boolean) => void;
	onClear?: () => void;
	theme: SearchCSS;
	showClearButton?: boolean;
	clearSearchLabel?: string;
	toggleAISearchButton?: JSX.Element;
};

export const SearchInputOverlay: FC<SearchInputOverlayProps> = ({
	theme,
	showClearButton,
	onClear,
	allowChangeExpand,
	clearSearchLabel,
	toggleAISearchButton,
}) => {
	return (
		<InputBoxOverlayRight>
			{showClearButton && (
				<ClearIconWrapper
					{...theme}
					role="button"
					onClick={onClear}
					onMouseEnter={() => {
						allowChangeExpand?.(false);
					}}
					onMouseLeave={() => {
						allowChangeExpand?.(true);
					}}
					tabIndex={0}
				>
					<SelectClearIcon
						color="currentColor"
						label={clearSearchLabel || 'Clear search session'}
						LEGACY_size="small"
					/>
				</ClearIconWrapper>
			)}
			{toggleAISearchButton}
		</InputBoxOverlayRight>
	);
};
