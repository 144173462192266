import type { IsProductAdminFragment } from './__types__/IsProductAdminFragment';

export const getIsProductAdminFromQuery = (
	queryData: IsProductAdminFragment | undefined,
): boolean =>
	Boolean(
		queryData?.user?.confluence?.operations?.some(
			(o) => o?.operation === 'administer' && o?.targetType === 'application',
		),
	);
