import gql from 'graphql-tag';

const webSectionFragment = gql`
	fragment webSectionFragment on WebSection {
		id
		label
		cacheKey
		styleClass
		items {
			accessKey
			completeKey
			hasCondition
			id
			icon {
				height
				width
				path
			}
			params {
				key
				value
			}
			label
			moduleKey
			section
			styleClass
			tooltip
			url
			urlWithoutContextPath
			weight
		}
	}
`;

export const WebItemLocationQuery = gql`
	query WebItemLocationQuery(
		$location: String
		$locations: [String]
		$contentId: ID
		$spaceKey: String
		$version: Int
	) {
		webItemSections(
			location: $location
			locations: $locations
			contentId: $contentId
			key: $spaceKey
			version: $version
		) {
			...webSectionFragment
		}
	}
	${webSectionFragment}
`;

export const WebItemLocationWithSpaceKeyQuery = gql`
	query WebItemLocationWithSpaceKeyQuery(
		$location: String
		$locations: [String]
		$spaceKey: String
		$version: Int
	) {
		space(key: $spaceKey) {
			id
			homepageId
			homepageWebSections(location: $location, locations: $locations, version: $version) {
				...webSectionFragment
			}
		}
	}
	${webSectionFragment}
`;
