import React, { type FunctionComponent, useState, useLayoutEffect, useRef, useEffect } from 'react';
import { SearchAnchor } from '../search-anchor';
import { default as SearchInput } from './search-input';
import { SEARCH_QUERY_KEY, setSessionItem, getSessionItem } from './session-storage-utils';
import {
	getInputSkeletonQuery,
	setInputSkeletonQuery,
	getInputSkeletonFocus,
	setInputSkeletonIsFocus,
	getInputSkeletonSelection,
	setInputSkeletonSelection,
	resetInputSkeletonState,
	removeInputSkeletonEventListeners,
} from './query-store';

export interface SearchInputSkeletonInteractiveProps {
	placeholder: string;
	onEnter?: (e: React.KeyboardEvent) => void;
	stickySearchEnabled?: boolean;
	shouldFillContainer?: boolean;
}

/**
 * An interactive search input skeleton that can be used as a placeholder. The
 * state of this input is stored in the browser's window object, and uses
 * the stored state as its initial state on mount.
 */
export const SearchInputSkeletonInteractive: FunctionComponent<
	SearchInputSkeletonInteractiveProps
> = ({ placeholder, stickySearchEnabled, onEnter, shouldFillContainer }) => {
	// Initialise stored inputSkeletonState if necessary
	const checkedForInputSkeletonState = useRef(false);
	if (!checkedForInputSkeletonState.current) {
		if (!window.inputSkeletonState) {
			resetInputSkeletonState();
		}
		checkedForInputSkeletonState.current = true;
	}

	const searchInputRef = useRef<HTMLInputElement>(null);

	// Use the stored state as initial values for expansion and query
	const [isExpanded, setIsExpanded] = useState(getInputSkeletonFocus());
	const [value, setValue] = useState(() => {
		const stickySearchQuery = getSessionItem(SEARCH_QUERY_KEY);
		return stickySearchEnabled && stickySearchQuery ? stickySearchQuery : getInputSkeletonQuery();
	});

	// Use the stored state to focus and set selection
	useLayoutEffect(() => {
		removeInputSkeletonEventListeners();
		const isFoucsed = getInputSkeletonFocus();
		const { selectionStart, selectionEnd, selectionDirection } = getInputSkeletonSelection();
		if (isFoucsed) {
			searchInputRef?.current?.focus();
			searchInputRef?.current?.setSelectionRange(selectionStart, selectionEnd, selectionDirection);
		}
	}, []);

	const onFocus = () => {
		setIsExpanded(true);
		setInputSkeletonIsFocus(true);
		searchInputRef?.current?.focus();
	};

	const onBlur = () => {
		setIsExpanded(false);
		setInputSkeletonIsFocus(false);
		if (!stickySearchEnabled) {
			setValue('');
			setInputSkeletonQuery('');
			setInputSkeletonSelection(0, 0, 'none');
		}
	};

	const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Escape') {
			searchInputRef?.current?.blur();
			onBlur();
		}
	};

	const onInput = (newValue: string) => {
		setValue(newValue);
		setInputSkeletonQuery(newValue);
		setInputSkeletonSelection(
			searchInputRef?.current?.selectionStart || 0,
			searchInputRef?.current?.selectionEnd || 0,
			searchInputRef?.current?.selectionDirection || 'none',
		);
		if (stickySearchEnabled) {
			setSessionItem(SEARCH_QUERY_KEY, newValue);
		}
	};

	const onSelect = () => {
		setInputSkeletonSelection(
			searchInputRef?.current?.selectionStart || 0,
			searchInputRef?.current?.selectionEnd || 0,
			searchInputRef?.current?.selectionDirection || 'none',
		);
	};

	// Fixes some instances where callbacks would not be called when the search
	// input is clicked as the component is being destroyed
	useEffect(() => {
		let storedSearchInputRef: HTMLInputElement | null = null;
		if (searchInputRef.current) {
			storedSearchInputRef = searchInputRef.current;
		}

		return () => {
			if (storedSearchInputRef) {
				setInputSkeletonQuery(storedSearchInputRef.value);
				setInputSkeletonIsFocus(document.activeElement === storedSearchInputRef);
				setInputSkeletonSelection(
					storedSearchInputRef.selectionStart || 0,
					storedSearchInputRef.selectionEnd || 0,
					storedSearchInputRef.selectionDirection || 'none',
				);
			}
		};
	}, []);

	return (
		<SearchAnchor
			isExpanded={isExpanded}
			onFocus={onFocus}
			onBlur={onBlur}
			onKeyDown={onKeyDown}
			shouldFillContainer={shouldFillContainer}
		>
			<SearchInput
				data-test-id="search-dialog-input-skeleton-interactive"
				isExpanded={isExpanded}
				placeholder={placeholder}
				onInput={onInput}
				onSelect={onSelect}
				onEnter={onEnter}
				shouldFillContainer={shouldFillContainer}
				value={value}
				ref={searchInputRef}
			/>
		</SearchAnchor>
	);
};
