import { useCallback } from 'react';

/**
 * This function can be used to create a hook for a lazy-loaded, memoized callback function
 * This is useful for code splitting callbacks which don't need to immediately execute (e.g. analytics callbacks)
 *
 * The `loader` parameter is similar to a Loadable loader. It is responsible for lazy importing the callback thunk.
 * The thunk is a function which takes callback dependencies as input and returns the callback.
 *
 * In the following example, the callback depends on `spaceKey` so
 * the thunk (outer function) takes `spaceKey` as input and returns the callback (inner function).
 *
 * // analyticsCallbacks.ts
 * export const onClick = (spaceKey: string) => () => console.log("Click with space key:", spaceKey);
 *
 * In a different module we can use this callback lazily:
 *
 * const useLazyOnClick = createLazyCallbackHook(
 *  async () => (await import(("./analyticsCallbacks"))).onClick
 * );
 *
 * Then we can use this hook instead of `useCallback`.
 * The code for the callback will not be loaded until the callback is called.
 *
 * const Component = () => {
 *  const [spaceKey] = usePageSpaceKey();
 *  const onClick = useLazyOnClick(spaceKey);
 *
 *  return <button onClick={onClick}>Click me</button>;
 * };
 */
export const createLazyCallbackHook = <
	Deps extends Array<any>,
	Callback extends (...args: any[]) => any,
>(
	loader: () => Promise<(...deps: Deps) => Callback>,
): ((...deps: Deps) => (...args: Parameters<Callback>) => Promise<ReturnType<Callback>>) => {
	return (...deps: Deps) =>
		useCallback(
			(...args: any) => {
				return loader().then((thunk) => thunk(...deps)(...args));
			},
			// Linter doesn't like spread syntax
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[...deps],
		);
};
