import PropTypes from 'prop-types';
import { Component } from 'react';

import { perfMarkEnd } from './performance';

export type PerformanceEndProps = {
	subject: string;
	subjectId?: string;
	details?: object;
	includeFeatureFlags?: boolean;
};

export class PerformanceEnd extends Component<PerformanceEndProps> {
	static propTypes = {
		subject: PropTypes.string.isRequired,
		subjectId: PropTypes.string,
		details: PropTypes.object,
		includeFeatureFlags: PropTypes.bool,
	};

	static defaultProps = {
		subjectId: '',
		includeFeatureFlags: false,
	};

	componentDidMount() {
		perfMarkEnd(this.props);
	}

	render() {
		return null;
	}
}
