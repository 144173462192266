import { useContext, useEffect } from 'react';

import { useLazyQuery as useApolloLazyQuery } from '@apollo/react-hooks';

import UFOInteractionContext from '@atlaskit/react-ufo/interaction-context';

import getOperationName from '../utils/get-operation-name';

export const useLazyQuery: typeof useApolloLazyQuery = (...args) => {
	const ufoContext = useContext(UFOInteractionContext);

	const apolloResult = useApolloLazyQuery(...args);

	const [query] = args;
	const operationName = getOperationName(query);

	const isLoading = apolloResult?.[1]?.loading;

	useEffect(() => {
		if (isLoading) {
			return ufoContext?.hold(operationName);
		}
	}, [isLoading, operationName, ufoContext]);

	return apolloResult;
};
